@font-face {
    font-family: 'Druk Cyr';
    src: url('/public/fonts/DrukCyr-Bold.eot');
    src: local('Druk Cyr Bold'), local('DrukCyr-Bold'),
        url('/public/fonts/DrukCyr-Bold.eot?#iefix') format('embedded-opentype'),
        url('/public/fonts/DrukCyr-Bold.woff2') format('woff2'),
        url('/public/fonts/DrukCyr-Bold.woff') format('woff'),
        url('/public/fonts/DrukCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Druk Cyr';
    src: url('/public/fonts/DrukCyr-Heavy.eot');
    src: local('Druk Cyr Bold'), local('DrukCyr-Heavy'),
        url('/public/fonts/DrukCyr-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/public/fonts/DrukCyr-Heavy.woff2') format('woff2'),
        url('/public/fonts/DrukCyr-Heavy.woff') format('woff'),
        url('/public/fonts/DrukCyr-Heavy.ttf') format('truetype');
    font-weight: 1000;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Druk Cyr';
    src: url('/public/fonts/DrukCyr-HeavyItalic.eot');
    src: local('Druk Cyr Bold'), local('DrukCyr-HeavyItalic'),
        url('/public/fonts/DrukCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('/public/fonts/DrukCyr-HeavyItalic.woff2') format('woff2'),
        url('/public/fonts/DrukCyr-HeavyItalic.woff') format('woff'),
        url('/public/fonts/DrukCyr-HeavyItalic.ttf') format('truetype');
    font-weight: 1000;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Druk Cyr';
    src: url('/public/fonts/DrukCyr-BoldItalic.eot');
    src: local('Druk Cyr Bold Italic'), local('DrukCyr-BoldItalic'),
        url('/public/fonts/DrukCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/public/fonts/DrukCyr-BoldItalic.woff2') format('woff2'),
        url('/public/fonts/DrukCyr-BoldItalic.woff') format('woff'),
        url('/public/fonts/DrukCyr-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'InterVariable';
    src: local('InterVariable'), local('InterDisplay'),
        url('/public/fonts/InterDisplay-Regular.woff2') format('woff2'),
        url('/public/fonts/InterDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: block;
    font-style: normal;
}

@font-face {
    font-family: 'InterVariable';
    src: local('InterVariable'), local('InterDisplay'),
        url('/public/fonts/InterDisplay-SemiBold.woff2') format('woff2'),
        url('/public/fonts/InterDisplay-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-display: block;
    font-style: normal;
}
